import { Layout } from 'antd';
const { Footer: AntFooter } = Layout;

export default function Footer() {
	return (
		<AntFooter className="text-center text-slate-400">
			Having issues? Contact help@weioffice.com for assistance.
		</AntFooter>
	);
}
